import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { StorageService } from "./sotarage.service";
import { log } from "console";

const env = environment.Url_BASE;
@Injectable({
  providedIn: "root",
})
export class AgencesService {
  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) { }

  // Méthode pour ajouter le token JWT aux en-têtes
  // Méthode pour ajouter le token JWT aux en-têtes
  getHeaders(): HttpHeaders {
    const token = this.storageService.getUser().token;
    return new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
  }

  //afficher tous les agences
  allAgence(): Observable<any> {
    return this.http.get(`${env}/user/agence/get`);
  }

  //AFFICHER UN AGENT EN FONCTION DE SON ID
  AfficherAgentParId(id: number): Observable<any> {
    return this.http.get(`${env}/bien/immo/user/agent/get/${id}`);
  }


  //AFFICHER UNE AGENCE EN FONCTION DE SON ID
  AfficherAgenceParId(id: number): Observable<any> {
    return this.http.get(`${env}/user/agence/${id}`);
  }

  AfficherAgenceParNom(nom: any): Observable<any> {
      const headers = this.getHeaders();
    return this.http.get(`${env}/user/agenceparNomAdmin/${nom}`, { headers });
  }


  //AFFICHER LA LISTE DES AGENTS EN FONCTION DE L'ID DE AGENCE
  AfficherListeAgentParAgence(id: number): Observable<any> {
    return this.http.get(`${env}/user/child/get/${id}`);
  }

  AfficherListeLouer(): Observable<any> {
    return this.http.get(`${env}/bien/immo/get/rent/all`);
  }

  AfficherListeAgentParSell(): Observable<any> {
    return this.http.get(`${env}/bien/immo/get/sell/all`);
  }

  AfficherListeAgentAll(): Observable<any> {
    const headers = this.getHeaders();

    return this.http.get(`${env}/admin/user/agent/get`, { headers });
  }

  AfficherDocument(id: number): Observable<any> {
    const headers = this.getHeaders();
    return this.http.get(`${env}/admin/user/doc/get/${id}`, { headers });
  }

  AfficherUserParId(id: number): Observable<any> {
    return this.http.get(`${env}/user/${id}`);
  }

  AfficherUserParUuId(id: number): Observable<any> {
    return this.http.get(`${env}/user/afficheruserparid/${id}`);
  }

  SupprimerAgent(id: number): Observable<any> {
    const headers = this.getHeaders();

    return this.http.post(`${env}/admin/user/child/delete/${id}`, null, { headers });
  }
  SupprimerUsers(id: number): Observable<any> {
    const headers = this.getHeaders();

    return this.http.post(`${env}/admin/user/delete/${id}`, null, { headers });
  }




  AjouterBlog(formData: FormData): Observable<any> {
    const headers = this.getHeaders();
    return this.http.post(`${env}/blog/ajouter`, formData, { headers });
  }

  //afficher tous les blogs
  blog(): Observable<any> {
    return this.http.get(`${env}/blog/afficher`);
  }
    //afficher tous les blogs
    blogParUuid(uuid:any): Observable<any> {
      return this.http.get(`${env}/blog/blogparuuid/${uuid}`);
    }
  

  SupprimerBlog(id: number): Observable<any> {
    const headers = this.getHeaders();

    return this.http.post(`${env}/admin/blog/delete/${id}`, null, { headers });
  }

  modifierBlog(id: number, formData: FormData): Observable<any> {
    const headers = this.getHeaders();

    return this.http.put(`${env}/blog/modifier/${id}`, formData, { headers });
  }

  allUser(): Observable<any> {
    const headers = this.getHeaders();
    return this.http.get(`${env}/user/alluser`, { headers });
  }
  certiFy(uuid: any): Observable<any> {
    const headers = this.getHeaders();
    return this.http.post(`${env}/user/setCertify/${uuid}`, null, { headers });
  }
  isActive(uuid: any): Observable<any> {
    const headers = this.getHeaders();

    return this.http.post(`${env}/user/active/${uuid}`, null, { headers });
  }

  //AFFICHER LA LISTE DES AGENCES
  AfficherLaListeAgence(): Observable<any> {
    const headers = this.getHeaders();
    return this.http.get(`${env}/user/alluser`, { headers });
  }

  AfficherUserConnecter(): Observable<any> {
    const headers = this.getHeaders();
    return this.http.get(`${env}/user/afficherConnecter`, { headers });
  }

  /// Modifiez le type de retour pour qu'il soit de type Observable<string>
  AfficherPhotoUserConnecter(): Observable<string> {
    const headers = this.getHeaders();
    return this.http.get<string>(`${env}/user/photo-utilisateur`, { headers });
  }
}
