import { Component, OnInit } from '@angular/core';
import { BienService } from 'src/app/services/bien.service';
import { ProductService } from 'src/app/shared/services/product.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  public openNotification: boolean = false;
  candidatures: any[]; // Assurez-vous de typer correctement vos candidatures
  total: any[]; // Même chose pour total
  givenDate: Date;

  temps: Date;
  

  constructor(private bienService: BienService) {
    this.givenDate = new Date("2023-10-14T00:02:37.000Z");
  }

  ngOnInit() {
    this.bienService.AfficherCandidature().subscribe((data) => {
      this.candidatures = data;
 
      // Filtrer les candidatures
      this.total = this.candidatures.filter(
        (candidature) => !candidature.isCancel && !candidature.isAccepted
      );


      this.total.forEach((candidature) => {
      
        candidature.temps = new Date(candidature?.updateAt); 
       
      });
    });
  }

  getDurationLabel(createdAt: Date | string): string {
    const createdAtDate = typeof createdAt === 'string' ? new Date(createdAt) : createdAt;
    
    if (!(createdAtDate instanceof Date) || isNaN(createdAtDate.getTime())) {
      return 'Invalid date';
    }
  
    const now = new Date();
    const timeDifference = now.getTime() - createdAtDate.getTime();
    const minutesDifference = Math.floor(timeDifference / (1000 * 60));
    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const monthsDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30));
    const yearsDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));
  
    if (minutesDifference > 0) {
      if (minutesDifference < 60) {
        return `${minutesDifference} minute${minutesDifference > 1 ? 's' : ''}`;
      } else if (hoursDifference < 24) {
        return `${hoursDifference} heure${hoursDifference > 1 ? 's' : ''}`;
      } else if (daysDifference < 30) {
        return `${daysDifference} jour${daysDifference > 1 ? 's' : ''}`;
      } else if (monthsDifference < 12) {
        return `${monthsDifference} mois${monthsDifference > 1 ? 's' : ''}`;
      } else {
        return `${yearsDifference} an${yearsDifference > 1 ? 's' : ''}`;
      }
    } else {
      return `${yearsDifference} an${yearsDifference > 1 ? 's' : ''}`;
    }
  }
  
  
  
  toggleNotificationMobile() {
    this.openNotification = !this.openNotification;
  }

}
